
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { IconSize, SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  components: { TmButton },
  props: {
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'medium',
    },
  },
})
