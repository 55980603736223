
import type { PropType } from 'vue'
import { defineComponent, nextTick, ref, watch } from 'vue'
import TmAutoSize from '@/components/shared/autoSize/TmAutoSize.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import cloneDeep from 'lodash/cloneDeep'
import { domainRegexp, removeProtocolFromUrl } from '@/services/utils'
import { tmAutoSizeIgnoreClass } from '@/definitions/shared/autoSize/data'
import TmFieldContainerRemoveBtn from '@/components/shared/tmFieldsContainer/TmFieldContainerRemoveBtn.vue'

export default defineComponent({
  components: {
    TmFieldContainerRemoveBtn,
    TmAutoSize,
    TmAvatar,
    TmButton,
    TmSpinner,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Add social media links',
    },
    isFocused: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
  },
  emits: ['focus', 'update:modelValue'],
  setup(props, { emit }) {
    const fieldRef = ref()
    const faviconsArr = ref<Array<string | undefined>>([])
    const hiddenItemsCount = ref()

    const getFavicon = async (domain: string, index: number) => {
      domain = domain.replace(/(^\w+:|^)\/\//, '')
      faviconsArr.value[index] = undefined
      if (domainRegexp.test(domain)) {
        faviconsArr.value[index] = 'loading'
        // for better search in Yandex API
        domain = domain.split('/')[0]
        // check Yandex API cause is working without CORS policy
        const response = await fetch(`https://favicon.yandex.net/favicon/${domain}`)
        if (response.ok) {
          // check Yandex favicon for size
          const blob = await response.blob()
          // 70 is an empty icon size
          // Google Api here for better quality in images, is not working with fetch because of CORS policy, but working with img:src
          faviconsArr.value[index] = blob.size > 70 ? `https://s2.googleusercontent.com/s2/favicons?domain=${domain}&sz=24` : undefined
        } else {
          faviconsArr.value[index] = undefined
        }
      }
    }

    const updateModelValue = (value: string, index: number) => {
      getFavicon(value, index)
      const modelValueCopy = cloneDeep(props.modelValue)
      modelValueCopy[index] = value
      emit('update:modelValue', modelValueCopy)
    }

    const addAnother = () => {
      faviconsArr.value.push(undefined)
      emit('update:modelValue', [...props.modelValue, ''])
      nextTick(() => focus())
    }

    const setFirstEmptyValue = () => {
      faviconsArr.value = [undefined]
      emit('update:modelValue', [''])
    }

    const deleteItem = (deleteIndex: number) => {
      if (props.modelValue?.length > 1) {
        faviconsArr.value.splice(deleteIndex, 1)
        emit('update:modelValue', props.modelValue?.filter((item, index) => index !== deleteIndex))
      } else {
        setFirstEmptyValue()
      }
    }

    const updateAllFavicons = () => {
      faviconsArr.value = []
      props.modelValue.forEach((item, index) => getFavicon(item, index))
    }

    const checkFieldValue = (fieldIndex: number) => {
      const domainWithoutProtocol = removeProtocolFromUrl(props.modelValue[fieldIndex])
      if (props.modelValue[fieldIndex].length !== domainWithoutProtocol.length) {
        props.modelValue[fieldIndex] = domainWithoutProtocol
        getFavicon(domainWithoutProtocol, fieldIndex)
      }
    }

    if (props.modelValue?.length) {
      updateAllFavicons()
    }

    const blur = () => {
      fieldRef.value.blur()
    }
    const focus = () => {
      fieldRef.value?.focus()
    }

    watch(
      () => props.isFocused,
      async (newValue) => {
        if (newValue) {
          if (!props.modelValue?.length) {
            setFirstEmptyValue()
          }
          await nextTick()
          focus()
        } else {
          const filteredModelValue = props.modelValue?.filter((item) => !!item)
          emit('update:modelValue', filteredModelValue)
          if (filteredModelValue.length !== props.modelValue?.length) {
            await nextTick()
            updateAllFavicons()
          }
        }
      })

    return {
      fieldRef,
      faviconsArr,
      hiddenItemsCount,
      tmAutoSizeIgnoreClass,
      domainRegexp,
      updateModelValue,
      addAnother,
      deleteItem,
      checkFieldValue,
      blur,
      focus,
    }
  },
})
