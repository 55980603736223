import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createBlock(_component_tm_button, {
    "icon-only": "",
    flat: "",
    color: "transparent",
    size: "medium"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_icon, {
        name: "delete",
        size: _ctx.iconSize
      }, null, 8, ["size"])
    ]),
    _: 1
  }))
}